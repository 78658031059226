import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Video from '../components/video'
import GLTFSection from '../components/sections/gltf-section'
import styles from '../templates/case.module.css';
import GallerySection from '../components/sections/gallery-section'
import CustomCursor from '../components/custom-cursor'

class CaseTemplate extends React.Component {
  render() {
    const project = get(this.props, 'data.contentfulCase');
    const url = typeof window !== 'undefined' ? window.location.href : '';

    return (
      <Layout location={this.props.location}>
        <SEO title={project.header} description={project.subheader} image={project.heroImage.file.url}></SEO>
        <div className={styles.headersection}>
          <div className={styles.headersectioncontent}>
            <h1>{project.client}</h1>
            <h2 className={styles.subheader}>{project.tagline}</h2>
          </div>
        </div>
        <div className={styles.hero}>
          <Img
                alt={project.heroImage.title}
                fluid={project.heroImage.fluid}
                durationFadeIn={0}
                style={{ position: `absolute`, top: 0, left: 0, width: "100%", height: "100%", zIndex: -1}}
                loading="lazy"
            />
        </div>
        <div className="content">
            <div className={styles.bodysection} dangerouslySetInnerHTML={{
              __html: project.whatBody.childMarkdownRemark.html,
            }}></div>
            {project.howBody != null &&
              <div className={styles.bodysection}
                dangerouslySetInnerHTML={{
                  __html: project.howBody.childMarkdownRemark.html,
                }}>
              </div>
            }
            {/* {project.sidebar != null && 
              <div className={styles.sidebar} dangerouslySetInnerHTML={{
                __html: project.sidebar.childMarkdownRemark.html,
              }}>
              </div>
            } */}
            {/* {project.quote != null && 
              <div className={styles.quote}>
                <q>{project.quote}</q>
                <span>{project.quotePerson}</span>
              </div>
            } */}
                        
          
          {project.youtubeId != null &&
              <Video
                youtubeId={project.youtubeId}
                className={styles.video}
              />
          }

          {project.gallery != null &&
            <GallerySection images={project.gallery}/>
          }

          {project.image360 != null &&
            <div className={styles.gallery360}>
              <h2>View more</h2>
              <iframe allowFullScreen={true} scrolling="no" width="100%" height="100%" src={"https://storage.googleapis.com/vrview/2.0/index.html?image="+project.image360.file.url}></iframe>
            </div>
          }

          {project.gltf != null &&  
            <GLTFSection data={project.gltf}/>
          } 
        </div>

        <CustomCursor/>
      </Layout>
    )
  }
}

export default CaseTemplate

export const pageQuery = graphql`
  query CaseBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCase(slug: { eq: $slug }) {
      heroImage {
        title
        fluid (maxWidth: 1920, quality: 70, sizes: "(max-width: 480px) 480px, (max-width: 960px) 960px, 1920px"){
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
      client
      header
      subheader
      tagline
      whatBody {
        childMarkdownRemark {
            html
        }
      }
      howBody {
        childMarkdownRemark {
            html
        }
      }
      gallery {
        title
        fluid (maxWidth: 1024, quality: 70, sizes: "(max-width: 480px) 480px, (max-width: 960px) 960px, 1024px"){
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
          contentType
        }
      }
      image360 {
        file {
          url
        }
      }
      youtubeId
      gltf {
        name
        sectionBody {
          childMarkdownRemark {
            html
          }
        }
        model {
          file {
            url
          }
        }
        variants {
          name
          color
        }
        modelZoom
        mediaWidth
        mediaOrder
        usdzModel {
          file {
            url
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
  }
`
