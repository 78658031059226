import React, {useContext} from 'react'
import 'video.js'
import 'videojs-youtube'
import 'video.js/dist/video-js.css'
import { MouseContext } from '../context/MouseContext';

const Video = ({ youtubeId, className }) => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);

  const youtubeUrl = "https://www.youtube.com/watch?v=" + youtubeId;

  return (
    <div 
      className={className}
      onMouseEnter={() => cursorChangeHandler("play")}
      onMouseLeave={() => cursorChangeHandler("none")}>
      <video
        poster={"https://img.youtube.com/vi/"+youtubeId+"/maxresdefault.jpg"}
        id="vid1"
        className="video-js vjs-default-skin"
        controls
        
        data-setup={'{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src":"'+youtubeUrl+'"}]}'}
      >
      </video>
    </div>
  );
}

export default Video