import React, { PureComponent, useState, useContext } from 'react'
import MediaQuery from 'react-responsive'
import Img from 'gatsby-image'
import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/dist/plugins/video'
import Slider from 'react-slick'

import 'yet-another-react-lightbox/dist/styles.css'

import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";
import '../gallery.css';
import styles from './gallery-section.module.css'

import { MouseContext } from '../../context/MouseContext';


const GallerySection = ({images}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);

  return(
    <section onMouseMove={(e) => {setMouseX(e.clientX); setMouseY(e.clientY);}}>     
      {/* Desktop gallery */}
      <MediaQuery minWidth={1024}>
        <div className={styles.gallerySection}>
          {images.map((node, index) => {
              return(
                <div className={styles.galleryItem} 
                  key={index}
                  onClick={() => {setIsOpen(true); setPhotoIndex(index);}}
                  onMouseEnter={() => cursorChangeHandler(node.file.contentType === "video/mp4" ? "play" : "zoom")}
                  onMouseLeave={() => cursorChangeHandler("none")}>

                  {node.file.contentType === "video/mp4" &&
                    <>
                      <video>
                        <source src={node.file.url} type="video/mp4" />
                      </video>
                      <div className={styles.playIcon}>
                        <img src="/images/play-icon.svg" alt="Play icon" />
                      </div>
                    </>
                  }
                  {node.file.contentType.includes("image") &&
                    <Img
                      alt={node.title}
                      fluid={{...node.fluid, aspectRatio: 1}}
                      style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}
                      fadeIn={false}
                      loading="lazy"
                    />
                  }
                </div>
                )
            })}
        </div>
        
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          index={photoIndex}
          zoomable={false}
          plugins={[Video]}
          on={{ view: ({ index: currentIndex }) => setPhotoIndex(currentIndex) }}
          video={{
            controls: true,
            playsInline: true,
            autoPlay: false,
            loop: false,
            muted: true,
            disablePictureInPicture: true,
            disableRemotePlayback: false,
          }}
          slides={images.map((node) => {
            return {
              type: node.file.contentType === "video/mp4" ? "video" : "image",
              src: node.file.url,
              title: node.title,
              sources: [{src: node.file.url, type: node.file.contentType}]

            }
          })}
        />
      </MediaQuery>
      
      {/* Mobile gallery */}
      <MediaQuery maxWidth={1023}>
        <Slider {...{
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: false,
          dots: true,
          mobileFirst: true
        }}>
          {images.map((node, index) => {
            return(
              <div>
                {node.file.contentType === "video/mp4" &&
                  <div style={{position: "relative", width: "100%", height: "0", paddingTop: "56.25%"}}>
                    <video controls style={{position: "absolute", width: "100%", height: "100%", top: 0}}>
                      <source src={node.file.url} type="video/mp4" />
                    </video>
                  </div>
                }
                {node.file.contentType === "image/jpeg" || node.file.contentType === "image/png" &&
                  <Img
                    alt={node.title}
                    fluid={node.fluid}
                    fadeIn={false}
                    loading="lazy"
                  />
                }
              </div>
              )
          })}
        </Slider>
      </MediaQuery>
    </section>
  );
}

export default GallerySection