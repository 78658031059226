
import React, { useState, useEffect, useContext } from 'react';
import style from './custom-cursor.css';
import { MouseContext } from '../context/MouseContext';

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);


  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener('mousemove', setFromEvent);
    return () => window.removeEventListener('mousemove', setFromEvent);
  }, []);

  return (
    <div 
        className={`customCursor ${cursorType}`}
        style={
        {
            transform: `translate(${position.x - 40}px, ${position.y - 40}px)`,
        }
        }>
            <div className={`customCursorInner`}>
                <div className={`zoomCursor`}>
                    <img src="/images/chevron.svg" className={`zoomIcon zoomIconLeft`} alt="Zoom icon left" />
                    <img src="/images/chevron.svg" className={`zoomIcon zoomIconRight`} alt="Zoom icon right" />
                </div>
                <img src="/images/play-icon.svg" className={`playCursor`} alt="Play icon" />
            </div>

        </div>
  );
};

export default CustomCursor;
