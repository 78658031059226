import React, { PureComponent } from 'react'
import styles from './pagesection.module.css'


export default class GLTFSection extends PureComponent {

  constructor(props) {
    super(props);
    this.gltfModel = React.createRef();
  }

  render() {
    const data = this.props.data;

    return(
      <div className={styles.gltf + ' ' + styles.withVariants + ' ' + (data.mediaWidth == "100" ? styles.image100 : "") + (data.mediaWidth == "60" ? styles.image60 : "") + (data.mediaWidth == "40" ? styles.image40 : "")}>        
        <model-viewer 
          camera-controls
          data-js-focus-visible
          disable-pan
          disable-zoom
          src={data.model.file.url}
          ar={data.usdzModel != null ? true : null}
          autoplay
          auto-rotate
          ios-src={data.usdzModel?.file.url}
          exposure="1.5"
          camera-orbit={"0deg 75deg "+data.modelZoom+"%"}
          min-camera-orbit={"-infinity 20deg "+data.modelZoom+"%"}
          max-camera-orbit={"infinity 90deg "+data.modelZoom+"%"}
          ref={this.gltfModel}
          >
        </model-viewer>
        {data.variants != null &&
          <div className={styles.gltfVariants}>
            {data.variants.map((node, index) => {
              return(
                <div key={index} className={styles.variantButtonCircle} onClick={(el) => {this.gltfModel.current.variantName = node.name}}>
                  <span className={styles.variantButton} style={{"backgroundColor":node.color}}></span>
                </div>
              )
            })}
          </div>
        }

      </div>
    );
  }
}
